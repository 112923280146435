import {$http} from '@/utils/https'


import {
    LOADING_CONTENT,
    PRODUCTS_LOADED,
    RESET_SPECIFICATIONS,
    SET_ARCH_SPECIFICATION,
    SET_BUILDING_ELEMENTS,
    SET_CALCULATORS, SET_CONSTRUCTOR_LOADING,
    SET_CONSTRUCTORS,
    SET_CONSTRUCTORS_PAGE,
    SET_CURRENT_CALCULATOR,
    SET_CURRENT_SNOW_CALCULATOR,
    SET_CURRENT_SPECIFICATION,
    SET_DOWNLOAD_LINK, SET_LOADING_BUILDINGS, SET_LOADING_CALCULATOR,
    SET_ROOF_SIZE,
    SET_SELECTED_SERVICES,
    SET_SNOW_LOAD_CATEGORIES,
    SET_SPECIFICATIONS,
    SHOW_MESSAGE_POPUP,
    SPECIFICATIONS_LOADED
} from '../mutation-types';

import {
    CET_ARCH_SPECIFICATION,
    CET_CALCULATORS,
    GET_BUILDING_ELEMENTS,
    GET_CONSTRUCTORS,
    GET_CONSTRUCTORS_PAGE,
    GET_SPECIFICATIONS,
    DOWNLOAD_PDF, GET_SNOW_LOAD_CATEGORIES, GET_CURRENT_CALCULATOR

} from '../action-types';
import axios from "axios";

const state = {
    constructors: '',
    constructorsData: '',
    buildingElements: '',
    contentLoaded: false,
    calculators: '',
    calculatorSpecifications: '',
    specificationList: '',
    roofSize: '',
    messageContent: {
        status: false,
        data: ''
    },
    downloadLink: '',
    productsLoaded: false,
    specificationsLoaded: false,
    currentSpecification: '',
    snowLoadCategories: '',
    currentSnowLoadCalculator: '',
    loadingSnowCalculator: false,
    loadingBuildings: false,
    constructorLoading: false
};

const getters = {
    constructorLoading: state => state.constructorLoading,
    loadingBuildings: state => state.loadingBuildings,
    currentSpecification: state => state.currentSpecification,
    productsLoaded: state => state.productsLoaded,
    constructors: state => state.constructors,
    constructorsData: state => state.constructorsData,
    buildingElements: state => state.buildingElements,
    calculators: state => state.calculators,
    calculatorSpecifications: state => state.calculatorSpecifications,
    specificationList: state => state.specificationList,
    roofSize: state => state.roofSize,
    messageContent: state => state.messageContent,
    downloadLink: state => state.downloadLink,
    specificationsLoaded: state => state.specificationsLoaded,
    snowLoadCategories: state => state.snowLoadCategories,
    currentSnowLoadCalculator: state => state.currentSnowLoadCalculator,
    loadingSnowCalculator: state => state.loadingSnowCalculator
};

const actions = {
    [GET_SPECIFICATIONS]: async ({commit}, payload) => {
        commit(SPECIFICATIONS_LOADED, true)
        try {
            const response = await $http.post(`v1/calculators/${payload.calculator_type_slug}/specifications/${payload.type}`, payload);
            if (payload.type === 'calculate') {
                let specificationList = {
                    specification: {
                        products: []
                    }
                }
                commit(RESET_SPECIFICATIONS, specificationList)
                commit(SET_ARCH_SPECIFICATION, response.data.data);

            } else {
                commit(SET_SPECIFICATIONS, response.data.data);
            }

        } catch (e) {
            throw e;
        } finally {
            commit(SPECIFICATIONS_LOADED, false)
        }
    },
    [GET_SNOW_LOAD_CATEGORIES]: async ({commit}) => {

        try {
            const response = await $http.get(`v1/snowload-calculators`);
            commit(SET_SNOW_LOAD_CATEGORIES, response.data.data);

        } catch (e) {
            throw e;
        } finally {

        }
    },
    [GET_CURRENT_CALCULATOR]: async ({commit}, payload) => {
        commit(SET_LOADING_CALCULATOR, true)
        try {
            const response = await $http.get(`v1/snowload-calculators/${payload.slug}`);
            commit(SET_CURRENT_SNOW_CALCULATOR, response.data);

        } catch (e) {
            throw e;
        } finally {
            commit(SET_LOADING_CALCULATOR, false)
        }
    },
    [CET_ARCH_SPECIFICATION]: async ({commit}, payload) => {
        commit(PRODUCTS_LOADED, true)
        try {
            const response = await $http.get(`v1/calculators/${payload.calculator_type_slug}/specifications`);
            commit(SET_ARCH_SPECIFICATION, response.data.data);

        } catch (e) {
            throw e;
        } finally {
            commit(PRODUCTS_LOADED, false)
        }
    },
    [CET_CALCULATORS]: async ({commit}, payload) => {
        commit(SET_CONSTRUCTOR_LOADING, true)
        try {
            const response = await $http.get(`v1/calculators`);
            commit(SET_CALCULATORS, response.data.data);
        } catch (e) {
            throw e;
        } finally {
            commit(SET_CONSTRUCTOR_LOADING, false)
        }
    },
    [GET_BUILDING_ELEMENTS]: async ({commit}, payload) => {
        try {
            const response = await $http.get(`v1/constructors/${payload.constructorId}/buildings/${payload.buildingId}`);
            commit(SET_BUILDING_ELEMENTS, response.data.data);
        } catch (e) {
            throw e;
        } finally {
        }
    },
    [GET_CONSTRUCTORS_PAGE]: async ({commit}) => {
        try {
            commit(LOADING_CONTENT, true);
            const response = await $http.get(`v1/page/architect`);
            commit(SET_CONSTRUCTORS_PAGE, response.data.data);
        } catch (e) {
            throw e;
        } finally {
            commit(LOADING_CONTENT, false);
        }
    },
    [GET_CONSTRUCTORS]: async ({commit}) => {
        try {
            commit(SET_LOADING_BUILDINGS, true);
            const response = await $http.get(`v1/constructors`);
            commit(SET_CONSTRUCTORS, response.data.data);
        } catch (e) {
            throw e;
        } finally {
            commit(SET_LOADING_BUILDINGS, false);
        }
    },

};

const mutations = {
    [SET_CONSTRUCTOR_LOADING](state, status) {
        state.constructorLoading = status
    },
    [SET_DOWNLOAD_LINK](state, data) {
        state.downloadLink = data
    },
    [SET_CURRENT_SPECIFICATION](state, data) {
        state.currentSpecification = data
    },
    [SET_CONSTRUCTORS](state, data) {
        state.constructors = data
    },
    [SET_CONSTRUCTORS_PAGE](state, data) {
        state.constructorsData = data
    },
    [LOADING_CONTENT](state, status) {
        state.contentLoaded = status
    },
    [SET_BUILDING_ELEMENTS](state, data) {
        state.buildingElements = data
    },
    [SET_CALCULATORS](state, data) {
        state.calculators = data
    },
    [SET_ARCH_SPECIFICATION](state, data) {
        state.calculatorSpecifications = data
    },
    [SET_SPECIFICATIONS](state, data) {
        state.specificationList = data
    },
    [SET_ROOF_SIZE](state, data) {
        state.roofSize = data
    },
    [SHOW_MESSAGE_POPUP](state, data) {
        state.messageContent.status = true
        state.messageContent.data = data
    },
    [RESET_SPECIFICATIONS](state, data) {
        state.specificationList = data
    },
    [PRODUCTS_LOADED](state, status) {
        state.productsLoaded = status
    },
    [SPECIFICATIONS_LOADED](state, status) {
        state.specificationsLoaded = status
    },
    [SET_SNOW_LOAD_CATEGORIES](state, data) {
        state.snowLoadCategories = data
    },
    [SET_CURRENT_SNOW_CALCULATOR](state, data) {
        state.currentSnowLoadCalculator = data
    },
    [SET_LOADING_CALCULATOR](state, status) {
        state.loadingSnowCalculator = status
    },
    [SET_LOADING_BUILDINGS](state, status) {
        state.loadingBuildings = status
    }
};

export default {
    state,
    getters,
    actions,
    mutations,
};
