import {$http} from '@/utils/https'
import {translation} from '@/helpers/translation';
import {globalLoader} from '@/helpers/variables/index';

import {
    CONTACTS_LOADING,
    SET_CONTACTS
} from '../mutation-types';

import {
    GET_CONTACTS

} from '../action-types';
import axios from "axios";

const state = {
    contacts: '',
    contactsLoading: false
};

const getters = {
    contacts: state => state.contacts,
    contactsLoading: state => state.contactsLoading
};

const actions = {


    [GET_CONTACTS]: async ({commit}) => {
        commit(CONTACTS_LOADING, true);
        try {
            const response = await $http.get(`v1/contacts`);
            commit(SET_CONTACTS, response.data.data);
        } catch (e) {
            throw e;
        } finally {
            commit(CONTACTS_LOADING, false);
        }
    },


};

const mutations = {
    [SET_CONTACTS](state, data) {
        state.contacts = data
    },
    [CONTACTS_LOADING](state, status) {
        state.contactsLoading = status
    }
};

export default {
    state,
    getters,
    actions,
    mutations,
};
