import {mapActions, mapGetters, mapMutations} from "vuex";

export default {
    data() {
        return {
            isShowSelect: false,
            isShowCatalog: false,
            emailValue: '',
            selectedCatalog: '',
            catalog: [
                {name: ' Монолітний полікарбонат'},
                {name: 'Профільований полікарбонат'},
                {name: 'Сотовий полікарбонат'},
                {name: 'Вставка до покрівлі із сендвіч панелі'},
                {name: 'Світлопрозорі фасадні системи'},
                {name: 'Світлопрозорі покрівельні системи'},
                {name: 'Аксесуари'},
                {name: 'Рішення для автомобілів'},
                {name: 'Агротеплиці'}
            ]
        }
    },
    props: {
        footerData: {
            type: Array,
            default: () => [],
        },
    },
    watch: {
        emailValue() {
            this.$refs.emailError.textContent = ''
        },
        selectedCatalog() {
            this.$router.push({
                name: 'category',
                params: {
                    slug: this.selectedCatalog.slug
                }
            })
            // console.log(this.selectedCatalog,'selectedCatalog');
        }
    },
    mounted() {
        this.getCategories()
        this.getContacts()

    },
    name: "main-footer",
    computed: {
        ...mapGetters({
            categoriesList: 'catalog/categoryList',
            contacts: 'contacts/contacts',
            privacyPolicy: 'setting/privacyPolicy'
        })
    },
    methods: {
        ...mapMutations({}),
        ...mapActions({
            sendSubscriptions: 'setting/SET_SUBSCRIPTION',
            getCategories: 'catalog/GET_CATEGORY',
            getContacts: 'contacts/GET_CONTACTS',
        }),

        getSocialLink(link) {
            if (this.contacts) {
                let social = this.contacts[2].contacts.find(el => el.type === link)
                return social.content
            }
        },
        sendEmail() {
            let regex = new RegExp('[a-z0-9]+@[a-z]+\.[a-z]{2,3}');
            if (regex.test(this.emailValue)) {
                this.sendSubscriptions({email: this.emailValue}).then(() => {
                    this.$toasted.success(this.$t('form.send'));
                    this.emailValue = ''
                })
            } else {
                this.$refs.emailError.textContent = this.$t('validation.email')
            }
        },
        showSelect() {
            this.isShowSelect = !this.isShowSelect
        },
        showCatalog() {
            this.isShowCatalog = !this.isShowCatalog
        }
    }
}
