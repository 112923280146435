import {$http} from '@/utils/https'
import Vue from 'vue';
import {
    ADD_GIFT_PRODUCT,
    ADD_ITEM_TO_BASKET,
    ADD_TOGETHER_PRODUCTS,
    BASKET_COUNT,
    CHANGE_BASKET_COUNT,
    DECREMENT_BASKET_LIST_COUNT,
    INCREMENT_BASKET_LIST_COUNT,
    REMOVE_ITEM_FROM_BASKET,
    RESET_BASKET,
    SET_BASKET,
    SET_BASKET_COUNT, SET_BASKET_LIST_COUNT,
    SET_BASKET_LOADING,
    SET_PRODUCT_COUNT,
    SET_SELECTED_SERVICES,
    SET_SERVICES
} from '../mutation-types';

import {
    BASKET_PRODUCT_ACTION,
    GET_BASKET,
    SELECT_SERVICES,
    UPDATE_BASKET,
    UPDATE_BASKET_CURRENT
} from '../action-types';

function saveBasket() {
    const parsed = JSON.stringify(state.basket);
    localStorage.setItem('products', parsed);
}

function saveServices() {
    const parsed = JSON.stringify(state.services);
    localStorage.setItem('services', parsed);
}

function checkBasketId() {
    if (!localStorage.getItem('guid')) {
        $http.get('v1/basket').then((response) => {
            state.basketId = response.data.data.guid
            localStorage.setItem('guid', response.data.data.guid)
        })
    }
}

const state = {
    basket: localStorage.getItem('products') ? JSON.parse(localStorage.getItem('products')) : [],
    basketLoading: false,
    secondBasket: [],
    services: '',
    productCount: 1,
    basketCount: 1,
    basketId: '',
    selectedServices: '',
    amountStatus: ''
};

const getters = {
    amountStatus: state => state.amountStatus,
    selectedServices: state => state.selectedServices,
    basketId: state => state.basketId,
    basketCount: state => state.basketCount,
    productCount: state => state.productCount,
    services: state => state.services,
    basket: state => state.basket,
    basketLoading: state => state.basketLoading,
    secondBasket: state => state.secondBasket
};

const actions = {
    [SELECT_SERVICES]: async ({commit}, payload) => {
        // commit(SET_BASKET_LOADING, true);
        try {
            const response = await $http.post(`v1/basket/services/${payload.service_id}`, payload);
            commit(SET_SELECTED_SERVICES, response.data.data);
        } catch (e) {
            throw e;
        } finally {
            // commit(SET_BASKET_LOADING, false);
        }
    },
    [BASKET_PRODUCT_ACTION]: async ({commit}, payload) => {

        commit(SET_BASKET_LOADING, true);
        try {
            // checkBasketId()
            if (payload.type === 'increment') {
                payload.action = 1
            }
            if (payload.type === 'decrement') {
                payload.action = -1
            }
            if (payload.type === 'delete') {
                payload.action = 0
            }
            if (payload.type === 'set_count') {
                payload.action = 2
            }

            const response = await $http.post(`v1/basket/products`, payload);

            commit(BASKET_COUNT, response.data.data);
            commit(ADD_GIFT_PRODUCT, response.data.data.basket)

        } catch (e) {
            throw e;
        } finally {
            commit(SET_BASKET_LOADING, false);
        }
    },
    [UPDATE_BASKET]: async ({commit}, data) => {
        commit(SET_BASKET_LOADING, true);
        try {
            // checkBasketId()
            const response = await $http.post('v1/basket', data);
            commit(BASKET_COUNT, response.data.data);
            commit(ADD_GIFT_PRODUCT, response.data.data.basket)

        } catch (e) {
            throw e;
        } finally {
            commit(SET_BASKET_LOADING, false);
        }
    },
    [UPDATE_BASKET_CURRENT]: async ({commit}) => {
        commit(SET_BASKET_LOADING, true);
        try {

            // checkBasketId()
            let newBasket = state.basketCount.basket || {};
            newBasket.products = state.basket


            const response = await $http.post('v1/basket', newBasket);

            commit(BASKET_COUNT, response.data.data);
            commit(ADD_GIFT_PRODUCT, response.data.data.basket)

        } catch (e) {
            throw e;
        } finally {
            commit(SET_BASKET_LOADING, false);
        }
    },
    [GET_BASKET]: async ({commit}, data) => {
        commit(SET_BASKET_LOADING, true);
        try {
            const response = await $http.get(`v1/basket`);

            commit(BASKET_COUNT, response.data.data);
            commit(ADD_GIFT_PRODUCT, response.data.data.basket)
        } catch (e) {
            // localStorage
            throw e;
        } finally {
            commit(SET_BASKET_LOADING, false);
        }
    },
};

const mutations = {
    [ADD_GIFT_PRODUCT](state, data) {
        let prods = [];
        let giftProduct = data.products.find(el => el.isGiftProduct)
        data.products.forEach(el => {
            let obj = {}
            obj.select_type = el

            if (!el.isGiftProduct) {
                obj.quantity = el.quantity
                obj.id = el.id
                obj.select_type.productPrice = {
                    price: {
                        price: el.price
                    },
                    priceOld: null,
                    hasDiscount: false,
                    baseUnit: {
                        title: el.unit,
                        fullTitle: "",
                        isM2: false
                    }
                }
                prods.push(obj)
                return;
            }
            obj.id = giftProduct.id
            obj.quantity = 1
            obj.select_type.amount = 1
            obj.selected_any_color = false
            obj.select_type.productPrice = {
                price: {
                    price: 0
                },
                priceOld: null,
                hasDiscount: false,
                baseUnit: {
                    title: "",
                    fullTitle: "",
                    isM2: false
                }
            }

            prods.push(obj)
        });

        // 1+1 action
        data.selectedGiftProducts.forEach(el => {
            let obj = {}
            obj.quantity = el.quantity
            obj.id = el.id
            obj.select_type = el
            obj.select_type.productPrice = {
                price: {
                    price: el.price
                },
                priceOld: null,
                hasDiscount: false,
                baseUnit: {
                    title: el.unit,
                    fullTitle: "",
                    isM2: false
                }
            }
            prods.push(obj)
        });

        prods.sort((a, b) => Number(a.select_type.isGiftProduct) - Number(b.select_type.isGiftProduct));
        state.basket = prods;

        saveBasket()

    },
    [SET_SELECTED_SERVICES](state, data) {
        state.selectedServices = data
    },
    [BASKET_COUNT](state, data) {
        state.basket = data.basket.products
            .map(product => {
                let obj = {}
                obj.id = product.id
                obj.quantity = product.quantity
                obj.select_type = product
                return obj
            })

        state.basketCount = data
        saveBasket()
    },
    [ADD_ITEM_TO_BASKET](state, obj) {
        /*if (!state.basket.length) {
          obj.quantity = 1
          obj.id = obj.select_type.id

          state.basket.push(obj);

          saveBasket();

          return;
        }

        const list = state.basket.filter(e => e.id === obj.select_type.id)

        if (!list.length) {
          obj.quantity = obj.amount || 1
          obj.id = obj.select_type.id

          state.basket.push(obj);
        } else {
          if (obj.select_type) {
            const index = state.basket.findIndex(e => e.id === obj.select_type.id && e.select_type.id === obj.select_type.id);

            if (index !== -1) {
              obj.quantity = state.basket[index].quantity + obj.amount
              obj.id = obj.select_type.id

              Vue.set(state.basket, index, obj)
            } else {
              obj.quantity = 1

              state.basket.push(obj);
            }
          } else {
            const index = state.basket.findIndex(e => e.id === obj.select_type.id);

            if (index !== -1) {
              obj.quantity = state.basket[index].quantity + 1
              obj.id = obj.select_type.id

              Vue.set(state.basket, index, obj)
            } else {
              obj.quantity = 1

              state.basket.push(obj);
            }
          }
        }*/
        saveBasket();
        // const parsed = JSON.stringify(state.basket);
        // localStorage.setItem('products', parsed);
    }, [REMOVE_ITEM_FROM_BASKET](state, obj) {
        let index
        if (obj.select_type) {
            index = state.basket.findIndex(e => e.id === obj.id && e.select_type.id === obj.select_type.id);
        } else {
            index = state.secondBasket.product.findIndex(e => e.id === obj.id);
            Vue.delete(state.secondBasket.product, index)

        }

        Vue.delete(state.basket, index)
        saveBasket();
    },
    [SET_BASKET_LIST_COUNT](state, obj) {
        state.amountStatus = obj.quantity
        let index
        if (obj.select_type) {
            index = state.basket.findIndex(e => e.id === obj.id && e.select_type.id === obj.select_type.id);

        } else {
            index = state.basket.findIndex(e => e.id === obj.id);
        }
        Vue.set(state.basket, index, obj);
        saveBasket();
    },
    [INCREMENT_BASKET_LIST_COUNT](state, obj) {
        state.amountStatus += 1
        let index
        if (obj.select_type) {
            index = state.basket.findIndex(e => e.id === obj.id && e.select_type.id === obj.select_type.id);

        } else {
            index = state.basket.findIndex(e => e.id === obj.id);
        }
        obj.quantity = obj.quantity + 1;
        Vue.set(state.basket, index, obj);
        saveBasket();
    }, [DECREMENT_BASKET_LIST_COUNT](state, obj) {
        state.amountStatus -= 1
        let index
        if (obj.select_type) {
            index = state.basket.findIndex(e => e.id === obj.id && e.select_type.id === obj.select_type.id);
        } else {
            index = state.basket.findIndex(e => e.id === obj.id);
        }
        obj.quantity = obj.quantity - 1;
        Vue.set(state.basket, index, obj);
        saveBasket();
    }, [SET_BASKET_COUNT](state, obj) {

        if (obj.select_type) {
            let index = index = state.basket.findIndex(e => e.id === obj.id && e.select_type.id === obj.select_type.id);

        } else {
            let index = index = state.basket.findIndex(e => e.id === obj.id);
        }
        obj.quantity = obj.quantity + 1;
        Vue.set(state.basket, index, obj);
        saveBasket();
    },
    [SET_BASKET_LOADING](state, status) {
        state.basketLoading = status
    },
    [SET_BASKET](state, data) {
        state.secondBasket = data
    },
    [SET_SERVICES](state, data) {
        state.services = data
        saveServices()
    },
    [CHANGE_BASKET_COUNT](state, obj) {
        const index = state.basket.findIndex(e => e.id === obj.id);
        if (index !== -1) {
            const index = state.basket.findIndex(e => e.id === obj.id);

            obj.select_type.amount = state.basket[index].select_type.amount + obj.select_type.amount
            obj.quantity = state.basket[index].quantity + obj.quantity
            Vue.set(state.basket, index, obj)

            saveBasket()
        } else {
            state.basket.push(obj)
            saveBasket()
        }
    },
    [ADD_TOGETHER_PRODUCTS](state, obj) {

        const index = state.basket.findIndex(e => e.id === obj.id);

        if (index !== -1) {

            obj.select_type.amount = state.basket[index].select_type.amount + obj.select_type.amount
            obj.quantity = state.basket[index].quantity + obj.quantity
            Vue.set(state.basket, index, obj)
            saveBasket()

        } else {
            state.basket.push(obj)
            saveBasket()
        }

    }, [SET_PRODUCT_COUNT](state, data) {
        state.productCount = data
    }, [RESET_BASKET](state) {
        state.basket = [];
        // localStorage.removeItem('products');
        saveBasket()
    }

};

export default {
    state, getters, actions, mutations,
};


