import {mapActions, mapGetters, mapMutations} from "vuex";
import {directive as onClickaway} from 'vue-clickaway';
import Vue from "vue";

export default {
    name: "main-header",
    components: {},
    directives: {
        onClickaway: onClickaway,
    },
    data() {
        return {
            cities: [
                {id: 0, city: !this.$route.params.lang ? 'Київ' : 'Киев'},
                {id: 1, city: !this.$route.params.lang ? 'Львів' : 'Львов'},
                {id: 2, city: !this.$route.params.lang ? 'Одеса' : 'Одесса'},
                {id: 2, city: !this.$route.params.lang ? 'Харків' : 'Харьков'}],
            currentCity: '',
            searchHeightDefault: '14px',
            searchHeightActive: '25px',
            basket: localStorage.getItem('products') ? JSON.parse(localStorage.getItem('products')).length : null,
            selectedProducts: JSON.parse(localStorage.getItem('products')),
            openSidebar: false,
            params: {
                title: '',
                description: '',
                keywords: '',
                image: '',
            },
        }
    },
    'head': function () {
        return {
            link: function () {

                const baseUrl = window.location.pathname.split('').slice(3).join('');

                return [
                    {
                        rel: 'canonical',
                        href: window.location.origin + baseUrl,
                        id: 'canonical'
                    },

                    {
                        rel: 'alternate',
                        hreflang: 'uk-UA',
                        href: window.location.origin + baseUrl,
                        id: 'ua'
                    },
                    {
                        rel: 'alternate',
                        hreflang: 'ru-UA',
                        href: window.location.origin + '/ru' + baseUrl,
                        id: 'ru'
                    },

                ]
            },

        }

    },
    props: {
        headerData: {
            type: Array,
            default: () => [],
        }

    },
    created() {


    },
    mounted() {
        console.log(this.$route.params.lang);
        this.setMeta()

    },
    computed: {
        ...mapGetters({
            products: 'basket/basket',
            isShowHeaderBasket: 'order/showHeaderBasket',
            isAuthenticated: 'auth/isAuthenticated',
            secondBasket: 'basket/secondBasket',
        }),
        ...mapGetters([
            'languages',
            'currentLanguage',

        ]),
        activeSearch() {
            Vue.nextTick(() => {
                let label = this.$refs.labelSearch
            })
        },
        getTotalSum() {
            let arr = []
            if (this.products.length) {
                this.products.forEach(el => {
                    arr.push(el.quantity * el.select_type.price)
                })
            }
            return arr.reduce((partialSum, a) => partialSum + a, 0);
        }
    },
    watch: {
        'products': function () {
            this.basket = JSON.parse(localStorage.getItem('products')) ? JSON.parse(localStorage.getItem('products')).length : null
            this.selectedProducts = JSON.parse(localStorage.getItem('products'))
        },
        '$route'() {
            // this.setMeta()
        },
        product: {
            immediate: true,
            handler(newVal) {
                if (newVal) {
                    this.setMeta()
                }
            }
        }
    },
    methods: {
        ...mapMutations({
            showHeaderBasket: 'order/SHOW_HEADER_BASKET',
            closeRegisterPopup: 'auth/SHOW_REGISTER_POPUP',
        }),
        ...mapActions({
            getBasket: 'basket/GET_BASKET',
            sendBasket: 'basket/UPDATE_BASKET',
        }),
        setMeta() {
            this.$emit('updateHead');
        },
        changeLanguage(code) {
            this.$setLanguage(code).then(data => {
                window.location.reload();
            });
        },
        searchFocus() {
            let close = this.$refs["closeIcon"]
            let searchWrapper = this.$refs["searchWrapper"]
            searchWrapper.classList.add('search-expanded')
            this.$refs["search"].addEventListener('transitionend', function () {
                close.style.display = 'block'
            })
            let search = this.$refs["search"].style
            search.height = this.searchHeightActive
            searchWrapper.style.position = 'absolute'
            searchWrapper.style.bottom = '-12px'
            search.transition = '0.3s linear'

            let callBack = this.$refs["callBack"].style
            callBack.opacity = '0'
            callBack.transition = '0.3s linear'

            let searchResult = this.$refs["searchResult"].style
            setTimeout(function () {
                searchResult.opacity = '1'
                searchResult.transition = '0.3s linear'
            }, 1000);

        },
        openBasket() {
            this.closePopUp(true)
        },
        openMenu() {
            this.openSidebar = !this.openSidebar
            document.getElementById('html').classList.toggle('hide')
            document.getElementById('html-overlay').classList.toggle('html-overlay')
        },
        goToLK() {

            if (!this.isAuthenticated) {
                this.closeRegisterPopup(true)
            } else {
                this.$router.push({name: 'profile'}).catch(() => {
                    console.log()
                })
            }
        },
        closePopUp(params) {
            this.getBasket()
            this.showHeaderBasket(params)
        },
        redirectToBasket() {
            this.showHeaderBasket(false)
            this.$router.push({name: 'basket'}).catch(() => {
                console.log()
            })
        },
        away: function () {
            this.showHeaderBasket(false)
        },
        closeMenu: function () {
            this.openSidebar = false
            document.getElementById('html').classList.remove('hide')
            document.getElementById('html-overlay').classList.remove('html-overlay')
        },

        anchorScroll(item) {
            // this.openMenu()
            // document.getElementById('html-overlay').classList.toggle('html-overlay')
            item = document.getElementById('calBack')

            if (item === null) {

                this.$router.push({name: 'home'}).then(() => {
                    const myEl = document.getElementById('calBack')
                    this.$smoothScroll({
                        scrollTo: myEl,
                    })
                }).catch(() => {
                    const myEl = document.getElementById('calBack')
                    this.$smoothScroll({
                        scrollTo: myEl,
                    })
                    console.log()
                })
            } else {

                const myEl = document.getElementById('calBack')
                this.$smoothScroll({
                    scrollTo: myEl,
                })
            }

        },
    },
}
