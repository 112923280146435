export default {
    data() {
        return {}
    },
    props: {
        label: {
            type: String,
            default: 'Submit'
        },
        slug: {
            type: String,
            default: ''
        },
        route: {
            type: String,
            default: ''
        },
        darkStyle: {
            type: Boolean,
            default: false
        },
        whiteStyle: {
            type: Boolean,
            default: false
        },
        grayStyle: {
            type: Boolean,
            default: false
        },
        changeSvg: {
            type: Boolean,
            default: false
        },
        svg: {
            type: String,
            default: ''
        }
    },
    methods: {
        allResult() {
            this.$emit('all-result')
        }
    }
}
