import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)

export const dynamicHead = {
    data() {
        return {
            params: {},
        }
    },
    'head': function () {
        const seo = this.params.seo?.data;

        // if (this.item && this.item.title && this.item.content) {
        return {
            title: () => {
                return {
                    inner: seo && seo.title || this.params && this.params.title || 'karbosnab',
                    complement: ' ',
                    separator: ' '
                }
            },
            meta: () => {


                let ogType = 'website';

                switch (this.$route.name) {
                    case 'article':
                        ogType = 'news'
                        break;
                    case 'manuals':
                    case 'manuals-category':
                    case 'manual':
                        ogType = 'article'
                        break;
                }
                const ogLocale = this.$route.params.lang === 'uk' ? 'uk_UA' : 'ru_RU'

                return [
                    {n: 'keywords', content: seo?.keywords || '', id: 'keywords'},
                    {n: 'title', content: seo?.title || this.params.title, id: 'title'},
                    {n: 'description', content: seo?.headDescription || '', id: 'description'},

                    {p: 'og:title', content: seo?.title || this.params.title, id: 'og_title'},
                    {p: 'og:description', content: seo?.headDescription || '', id: 'og_description'},
                    {p: 'og:image', content: seo?.image || this.params.image, id: 'og_image'},
                    {p: 'og:site_name', content: 'karbosnab', id: 'og_site_name'},
                    {p: 'og:url', content: window.location, id: 'og_url'},
                    {p: 'og:type', content: ogType, id: 'og_website'},
                    {p: 'og:locale', content: ogLocale, id: 'og_locale'},

                    {p: 'twitter:title', content: seo?.title || this.params.title, id: 'twitter_title'},
                    {p: 'twitter:description', content: seo?.headDescription || '', id: 'twitter_description'},
                    {p: 'twitter:image', content: seo?.image || this.params.image, id: 'twitter_image'}
                ]
            },

            script: function () {
                // let obj = {
                //     "@context": "https://schema.org",
                //     "@type": "FAQPage",
                //     "mainEntity": [{
                //         "@type": "Question",
                //         "name": "What is the return policy?",
                //         "acceptedAnswer": {
                //             "@type": "Answer",
                //             "text": this.faqText
                //         }
                //     }]
                // }

                const seo = this.params.seo?.data;

                const list = [
                    {
                        id: 'FAQPage',
                        t: 'application/ld+json',
                        i: seo?.faqContent
                    },
                ];

                if (this.$route.name === 'home') {
                    list.push({
                        id: 'homeCompany',
                        t: 'application/ld+json',
                        i: this.params.seo?.homePageContacts
                    })
                }

                if (this.$route.path.match('/product/.*')) {
                    list.push({
                        id: 'productSchema',
                        t: 'application/ld+json',
                        i: this.params.seo?.productSchema
                    })
                }

                return list
                // }

            }
        }

    },
    watch: {
        '$route'() {
            this.setMeta()
        },
        product: {
            immediate: true,
            handler(newVal) {
                if (newVal) {
                    this.setMeta()
                }
            }
        }
    },
    methods: {
        setPageItem(item) {
            this.params = item

            this.$emit('updateHead');
        },
        setMeta() {
            this.$emit('updateHead');
        },
    }
}
