import {$http} from '@/utils/https'
import axios from 'axios'

import {
    SET_LOGIN_SMS_CODE,
    SET_CONTACT_DATA,
    SET_DATA_OTHER_RECIPIENT,
    NOVA_POSHTA_SET_TOWNS,
    NOVA_POSHTA_SET_DEPARTMENS,
    CAT_SET_TOWNS,
    CAT_SET_DEPARTMENS,
    DELIVERY_SET_TOWNS,
    DELIVERY_SET_DEPARTMENS,
    SET_SERVICES_LIST,
    LOADING_SERVICES_LIST,
    NOVA_POSHTA_SET_TOWNS_LOADING,
    NOVA_POSHTA_SET_DEPARTMENS_LOADING,
    CAT_SET_TOWNS_LOADING,
    CAT_SET_DEPARTMENS_LOADING,
    DELIVERY_SET_TOWNS_LOADING,
    DELIVERY_SET_DEPARTMENS_LOADING,
    SET_PAYMENTS_LIST,
    PAYMENTS_LIST_LOADING,
    SET_DELIVERIES_LIST,
    DELIVERIES_LIST_LOADING,
    SET_CALCULATION_TYPE,
    CALCULATION_TYPE_LOADING,
    SET_SEND_FEEDBACK,
    SET_IPN,
    SET_IPN_LOADING,
    RESET_IPN,
    SELECT_DELIVERY,
    SELECT_PAYMENT,
    USER_DATA_FILLED,
    SET_ORDER,
    SET_ORDER_LOADING,
    SHOW_SUCCESS_POPUP,
    FAST_ORDER_POPUP,
    SET_PRODUCT_FOR_FAST_ORDER,
    SHOW_HEADER_BASKET,
    FAST_ORDER_LOADING,
    RESET_PAYMENT_DATA,
    SHOW_SUCCESS_PAYMENT_POPUP,
    SET_TOWNS_LIST,
    SET_DEPARTMENTS_LIST, SET_ADDRESS_LIST, RESET_DELIVERY_LIST, ADDRESS_LIST_LOADING, SHOW_ORDER_COLOR_POPUP,
} from '../mutation-types';

import {
    NOVA_POSHTA_GET_TOWNS,
    NOVA_POSHTA_GET_DEPARTMENS,
    CAT_GET_TOWNS,
    CAT_GET_DEPARTMENS,
    DELIVERY_GET_TOWNS,
    DELIVERY_GET_DEPARTMENS,
    GET_SERVICES_LIST,
    GET_PAYMENTS_TYPE,
    GET_DELIVERIES_LIST,
    CALCULATION_TYPE,
    SEND_IPN, SEND_ORDER, SEND_FAST_ORDER, GET_TOWNS_LIST, GET_DEPARTMENTS_LIST, GET_ADDRESS_LIST,
} from '../action-types';
import store from "@/store";

const state = {
    smsLogin: {
        phone: '',
        code: ''
    },
    loginFirst: {
        name: '',
        middle_name: '',
        phone: '',
        email: '',
        town: ''
    },
    towns: [],
    departments: [],
    otherRecipientData: {
        name: '',
        surname: '',
        phone: ''
    },
    catTowns: [],
    catDepartments: [],
    deliveryTowns: [],
    deliveryDepartments: [],
    deliveryRegions: [],
    loadingServicesList: false,
    servicesList: [],
    npTownsLoading: false,
    npDepartmentsLoading: false,
    satTownsLoading: false,
    setDepartmentsLoading: false,
    deliveryTownsLoading: false,
    deliveryDepartmentsLoading: false,
    deliveryRegionLoading: false,
    paymentsList: [],
    paymentsListLoading: false,
    deliveriesList: '',
    deliveriesListLoading: false,
    calculationTypeList: [],
    calculationTypeListLoading: false,
    setIpn: [],
    setIpnLoading: false,
    selectedDelivery: '',
    selectPayment: '',
    userDataFilled: {status: false},
    orderData: '',
    orderLoading: false,
    showSuccessPopup: false,
    showFastOrder: false,
    fastOrderProduct: '',
    showHeaderBasket: false,
    fastOrderLoading: false,
    showSuccessPaymentPopup: false,
    citiesList: [],
    departmentsList: [],
    addressesList: [],
    addressListLoading: false,
    orderColorPopup: false
};

const getters = {
    orderColorPopup: state => state.orderColorPopup,
    addressListLoading: state => state.addressListLoading,
    addressesList: state => state.addressesList,
    departmentsList: state => state.departmentsList,
    citiesList: state => state.citiesList,
    showSuccessPaymentPopup: state => state.showSuccessPaymentPopup,
    fastOrderLoading: state => state.fastOrderLoading,
    showHeaderBasket: state => state.showHeaderBasket,
    fastOrderProduct: state => state.fastOrderProduct,
    showFastOrder: state => state.showFastOrder,
    showSuccessPopup: state => state.showSuccessPopup,
    orderLoading: state => state.orderLoading,
    orderData: state => state.orderData,
    userDataFilled: state => state.userDataFilled,
    selectPayment: state => state.selectPayment,
    selectedDelivery: state => state.selectedDelivery,
    setIpnLoading: state => state.setIpnLoading,
    setIpn: state => state.setIpn,
    calculationTypeListLoading: state => state.calculationTypeListLoading,
    calculationTypeList: state => state.calculationTypeList,
    deliveriesListLoading: state => state.deliveriesListLoading,
    deliveriesList: state => state.deliveriesList,
    paymentsList: state => state.paymentsList,
    paymentsListLoading: state => state.paymentsListLoading,
    smsLogin: state => state.smsLogin,
    townsList: state => state.towns,
    departmentList: state => state.departments,
    loginFirst: state => state.loginFirst,
    otherRecipientData: state => state.otherRecipientData,
    catTowns: state => state.catTowns,
    catDepartments: state => state.catDepartments,
    deliveryTowns: state => state.deliveryTowns,
    deliveryDepartments: state => state.deliveryDepartments,
    loadingServicesList: state => state.loadingServicesList,
    servicesList: state => state.servicesList,
    npTownsLoading: state => state.npTownsLoading,
    npDepartmentsLoading: state => state.npDepartmentsLoading,
    satTownsLoading: state => state.satTownsLoading,
    setDepartmentsLoading: state => state.setDepartmentsLoading,
    deliveryTownsLoading: state => state.deliveryTownsLoading,
    deliveryDepartmentsLoading: state => state.deliveryDepartmentsLoading,
};

const actions = {
    [GET_ADDRESS_LIST]: async ({commit}, payload) => {
        commit(ADDRESS_LIST_LOADING, true);
        try {
            const response = await $http.get(`v1/streets?order=${payload.order}&city_ref=${payload.city_ref}&city_name=${payload.city_name}&street_name=${payload.street_name}&service=${payload.service}`);
            commit(SET_ADDRESS_LIST, response.data);
        } catch (e) {
            throw e;
        } finally {
            commit(ADDRESS_LIST_LOADING, false);
        }
    },
    [GET_DEPARTMENTS_LIST]: async ({commit}, payload) => {
        commit(NOVA_POSHTA_SET_DEPARTMENS_LOADING, true);
        try {
            const response = await $http.get(`v1/warehouses?order=${payload.order}&city_ref=${payload.city_ref}&city_name=${payload.city_name}&service=${payload.service}`);
            commit(SET_DEPARTMENTS_LIST, response.data.data);
        } catch (e) {
            throw e;
        } finally {
            commit(NOVA_POSHTA_SET_DEPARTMENS_LOADING, false);
        }
    },
    [GET_TOWNS_LIST]: async ({commit}, payload) => {
        commit(NOVA_POSHTA_SET_TOWNS_LOADING, true);
        try {
            const response = await $http.get(`v1/cities?city_name=${payload.city_name}&service=${payload.service}`);
            commit(SET_TOWNS_LIST, response.data);
        } catch (e) {
            throw e;
        } finally {
            commit(NOVA_POSHTA_SET_TOWNS_LOADING, false);
        }
    },
    [SEND_FAST_ORDER]: async ({commit}, payload) => {
        commit(FAST_ORDER_LOADING, true);
        try {
            const response = await $http.post(`v1/order/fast-order/confirm`, payload);
            commit(SET_DELIVERIES_LIST, response.data.data);

        } catch (e) {
            throw e;
        } finally {
            commit(FAST_ORDER_LOADING, false);
        }
    },
    [NOVA_POSHTA_GET_TOWNS]: async ({commit}, payload) => {
        commit(NOVA_POSHTA_SET_TOWNS_LOADING, true);
        try {
            const response = await axios({
                method: 'POST',
                url: "https://api.novaposhta.ua/v2.0/json/",
                data: payload
            })
            commit(NOVA_POSHTA_SET_TOWNS, response.data.data)
            commit(NOVA_POSHTA_SET_DEPARTMENS, [])
        } catch (e) {
            throw e;
        } finally {
            commit(NOVA_POSHTA_SET_TOWNS_LOADING, false);
        }
    },
    [NOVA_POSHTA_GET_DEPARTMENS]: async ({commit}, payload) => {
        commit(NOVA_POSHTA_SET_DEPARTMENS_LOADING, true);
        try {
            const response = await axios({
                method: 'POST',
                url: "https://api.novaposhta.ua/v2.0/json/",
                data: payload
            })
            commit(NOVA_POSHTA_SET_DEPARTMENS, response.data.data)
        } catch (e) {
            throw e;
        } finally {
            commit(NOVA_POSHTA_SET_DEPARTMENS_LOADING, false);
        }
    },
    [CAT_GET_TOWNS]: async ({commit}, city) => {
        let locale = store.getters.currentLanguage.translationKey
        commit(CAT_SET_TOWNS_LOADING, true);
        try {
            const response = await axios({
                method: 'GET',
                url: `https://api.sat.ua/study/hs/api/v1.0/main/json/getTowns?language=${locale}&searchString=${city}`,
                data: city
            })
            commit(CAT_SET_TOWNS, response.data.data)
        } catch (e) {
            throw e;
        } finally {
            commit(CAT_SET_TOWNS_LOADING, false);
        }
    },
    [CAT_GET_DEPARTMENS]: async ({commit}, department) => {
        let locale = store.getters.currentLanguage.translationKey
        commit(CAT_SET_DEPARTMENS_LOADING, true);
        try {
            const response = await axios({
                method: 'GET',
                url: `https://api.sat.ua/study/hs/api/v1.0/main/json/getRsp?language=${locale}&searchString=${department}`,
                data: department
            })
            commit(CAT_SET_DEPARTMENS, response.data.data)
        } catch (e) {
            throw e;
        } finally {
            commit(CAT_SET_DEPARTMENS_LOADING, false);
        }
    },
    [DELIVERY_GET_TOWNS]: async ({commit}, data) => {
        let language
        let locale = store.getters.currentLanguage.translationKey
        locale === 'rshowHeaderBasketu' ? language = 'ru-RU' : language = 'uk-UA'
        commit(DELIVERY_SET_TOWNS_LOADING, true);
        try {
            const response = await axios({
                method: 'GET',
                url: `https://www.delivery-auto.com/api/v4/Public/GetAreasList?culture=${language}`,
                data: data,
                dataType: 'json',
            })
            commit(DELIVERY_SET_TOWNS, response.data.data)
        } catch (e) {
            throw e;
        } finally {
            commit(DELIVERY_SET_TOWNS_LOADING, false);
        }
    },

    [DELIVERY_GET_DEPARTMENS]: async ({commit}, payload) => {
        let language
        let locale = store.getters.currentLanguage.translationKey
        locale === 'ru' ? language = 'ru-RU' : language = 'uk-UA'
        commit(DELIVERY_SET_DEPARTMENS_LOADING, true);
        try {
            const response = await axios({
                method: 'GET',
                url: `https://www.delivery-auto.com/api/v4/Public/GetWarehousesList?culture=${language}&CityId=${payload.citiId}&RegionId=${payload.regionId}&country=1`
            })
            commit(DELIVERY_SET_DEPARTMENS, response.data.data)
        } catch (e) {
            throw e;
        } finally {
            commit(DELIVERY_SET_DEPARTMENS_LOADING, false);
        }
    },
    [GET_SERVICES_LIST]: async ({commit}) => {
        commit(LOADING_SERVICES_LIST, true);
        try {
            const response = await $http.get(`v1/order-services`);
            commit(SET_SERVICES_LIST, response.data.data);
        } catch (e) {
            throw e;
        } finally {
            commit(LOADING_SERVICES_LIST, false);
        }
    },
    [GET_PAYMENTS_TYPE]: async ({commit}) => {
        commit(PAYMENTS_LIST_LOADING, true);
        try {
            const response = await $http.get(`v1/payments`);
            commit(SET_PAYMENTS_LIST, response.data.data);
        } catch (e) {
            throw e;
        } finally {
            commit(PAYMENTS_LIST_LOADING, false);
        }
    },
    [GET_DELIVERIES_LIST]: async ({commit}) => {
        commit(DELIVERIES_LIST_LOADING, true);
        try {
            const response = await $http.get(`v1/deliveries`);
            commit(SET_DELIVERIES_LIST, response.data.data);
        } catch (e) {
            throw e;
        } finally {
            commit(DELIVERIES_LIST_LOADING, false);
        }
    },
    [CALCULATION_TYPE]: async ({commit}) => {
        commit(CALCULATION_TYPE_LOADING, true);
        try {
            const response = await $http.get(`v1/ownership-types`);
            commit(SET_CALCULATION_TYPE, response.data.data);
        } catch (e) {
            throw e;
        } finally {
            commit(CALCULATION_TYPE_LOADING, false);
        }
    },
    [SEND_IPN]: async ({commit}, payload) => {
        commit(SET_IPN_LOADING, true);
        try {
            const result = await $http.post('v1/ownership-info', payload);
            commit(SET_IPN, result.data)
        } catch (e) {
            throw e;
        } finally {
            commit(SET_IPN_LOADING, false);
        }
    },
    [SEND_ORDER]: async ({commit}, payload) => {
        commit(SET_ORDER_LOADING, true);
        try {
            const result = await $http.post(`v1/order/confirm`, payload);
            commit(SET_ORDER, result.data)
        } catch (e) {
            throw e;
        } finally {
            commit(SET_ORDER_LOADING, false);
        }
    },
};

const mutations = {
    [SHOW_SUCCESS_PAYMENT_POPUP](state, status) {
        state.showSuccessPaymentPopup = status
    },
    [FAST_ORDER_LOADING](state, status) {
        state.fastOrderLoading = status
    },
    [SET_LOGIN_SMS_CODE](state, data) {
        state.loginFirst = data;
    },
    [SET_CONTACT_DATA](state, data) {
        state.smsLogin = data;
    },
    [NOVA_POSHTA_SET_TOWNS](state, data) {
        state.towns = data;
    },
    [NOVA_POSHTA_SET_DEPARTMENS](state, data) {
        state.departments = data;
    },
    [SET_DATA_OTHER_RECIPIENT](state, data) {
        state.otherRecipientData = data;
    },
    [CAT_SET_TOWNS](state, data) {
        state.catTowns = data;
    },
    [CAT_SET_DEPARTMENS](state, data) {
        state.catDepartments = data;
    },
    [DELIVERY_SET_DEPARTMENS](state, data) {
        state.deliveryDepartments = data;
    },
    [DELIVERY_SET_TOWNS](state, data) {
        state.deliveryTowns = data;
    },
    [LOADING_SERVICES_LIST](state, status) {
        state.loadingServicesList = status
    },
    [SET_SERVICES_LIST](state, data) {
        state.servicesList = data
    },
    [NOVA_POSHTA_SET_TOWNS_LOADING](state, status) {
        state.npTownsLoading = status
    },
    [NOVA_POSHTA_SET_DEPARTMENS_LOADING](state, status) {
        state.npDepartmentsLoading = status
    },
    [CAT_SET_TOWNS_LOADING](state, status) {
        state.satTownsLoading = status
    },
    [CAT_SET_DEPARTMENS_LOADING](state, status) {
        state.setDepartmentsLoading = status
    },
    [DELIVERY_SET_TOWNS_LOADING](state, status) {
        state.deliveryTownsLoading = status
    },
    [DELIVERY_SET_DEPARTMENS_LOADING](state, status) {
        state.deliveryDepartmentsLoadiselectPaymentng = status
    },
    [PAYMENTS_LIST_LOADING](state, status) {
        state.paymentsListLoading = status
    },
    [SET_PAYMENTS_LIST](staRESET_IPNte, data) {
        state.paymentsList = data
    },
    [RESET_PAYMENT_DATA](state) {
        state.orderData = ''
    },
    [SET_DELIVERIES_LIST](state, data) {
        state.deliveriesList = data
    },
    [DELIVERIES_LIST_LOADING](state, status) {
        state.deliveriesListLoading = status
    },
    [CALCULATION_TYPE_LOADING](state, status) {
        state.calculationTypeListLoading = status
    },
    [SET_CALCULATION_TYPE](state, data) {
        state.calculationTypeList = data
    },
    [SET_IPN_LOADING](state, status) {
        state.setIpnLoading = status
    },
    [SET_IPN](state, data) {
        state.setIpn = data
    },
    [RESET_IPN](state) {
        state.setIpn = []
    },
    [SELECT_DELIVERY](state, data) {
        state.selectedDelivery = data
    },
    [SELECT_PAYMENT](state, data) {
        state.selectPayment = data
    },
    [USER_DATA_FILLED](state, status) {
        state.userDataFilled = status
    },
    [SET_ORDER](state, data) {
        state.orderData = data
    },
    [SET_ORDER_LOADING](state, status) {
        state.orderLoading = status
    },
    [SHOW_SUCCESS_POPUP](state, status) {
        state.showSuccessPopup = status
    },
    [FAST_ORDER_POPUP](state, status) {
        state.showFastOrder = status
    },
    [SET_PRODUCT_FOR_FAST_ORDER](state, data) {
        state.fastOrderProduct = data
    },
    [SHOW_HEADER_BASKET](state, status) {
        state.showHeaderBasket = status
    },
    [SET_TOWNS_LIST](state, data) {
        state.citiesList = data
    },
    [SET_DEPARTMENTS_LIST](state, data) {
        state.departmentsList = data
    },
    [SET_ADDRESS_LIST](state, data) {
        state.addressesList = data
    },
    [RESET_DELIVERY_LIST](state, data) {
        state[data] = []
    },
    [ADDRESS_LIST_LOADING](state, status) {
        state.addressListLoading = status
    },
    [SHOW_ORDER_COLOR_POPUP](state, status) {
        state.orderColorPopup = status
    }

};

export default {
    state,
    getters,
    actions,
    mutations,
};
