import {mapMutations, mapActions, mapGetters} from "vuex";

//sections
import SmallLoader from "@/components/small-loader/index.vue";
import {loginByPhone} from "@/mixins/loginByPhone.js";

export default {
    name: "auth",
    components: {
        SmallLoader,
    },
    mixins: [loginByPhone],
    data() {
        return {
            selectedService: [],
        }
    },
    created() {
    },
    mounted() {

    },
    watch: {},
    computed: {
        ...mapGetters({})
    },
    methods: {
        ...mapMutations({
            setToken: 'auth/SET_TOKEN'
        }),
        ...mapActions({}),
    }
}
