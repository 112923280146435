<template>
  <div class="loader-wrapper">
    <div id="loader">
      <div class="bar"></div>
      <div class="bar"></div>
      <div class="bar"></div>
      <div class="bar"></div>
      <div class="bar"></div>
      <div class="bar"></div>
      <div class="bar"></div>
      <div class="bar"></div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'loader',
}
</script>
<style lang="scss">
@import "../../assets/scss/vars";

.loader-wrapper {
  background: $gray-gradient;
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  z-index: 999;

  #loader {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 300px;
    height: 180px;
    margin-left: -150px;
    margin-top: -90px;
  }

  .bar {
    position: absolute;
    animation: jump-loader 1s ease infinite;
    -webkit-animation: jump-loader 1s ease infinite;
    border-radius: 2px;
    background: linear-gradient(to bottom, transparent, rgba(0, 0, 0, .1));
  }

  .bar:nth-child(1) {
    left: 0;
  }

  .bar:nth-child(2) {
    left: 40px;
    animation-delay: .1s;
    -webkit-animation-delay: .1s;
  }

  .bar:nth-child(3) {
    left: 80px;
    animation-delay: .2s;
    -webkit-animation-delay: .2s;
  }

  .bar:nth-child(4) {
    left: 120px;
    animation-delay: .3s;
    -webkit-animation-delay: .3s;
  }

  .bar:nth-child(5) {
    left: 160px;
    animation-delay: .4s;
    -webkit-animation-delay: .4s;
  }

  .bar:nth-child(6) {
    left: 200px;
    animation-delay: .5s;
    -webkit-animation-delay: .5s;
  }

  .bar:nth-child(7) {
    left: 240px;
    animation-delay: .6s;
    -webkit-animation-delay: .6s;
  }

  .bar:nth-child(8) {
    left: 280px;
    animation-delay: .7s;
    -webkit-animation-delay: .7s;
  }

  @-webkit-keyframes jump-loader {
    0% {
      width: 20px;
      height: 180px;
      margin-left: 0;
      margin-top: 0;
      box-shadow: inset 0 1px 1px rgba(255, 255, 255, .2), 0 2px 2px rgba(0, 0, 0, .7);
    }
    50% {
      width: 26px;
      height: 200px;
      margin-left: -3px;
      margin-top: -10px;
      box-shadow: inset 0 1px 1px rgba(255, 255, 255, .2), 0 20px 25px rgba(0, 0, 0, .7);
    }
    100% {
      width: 20px;
      height: 180px;
      margin-left: 0;
      margin-top: 0;
      box-shadow: inset 0 1px 1px rgba(255, 255, 255, .2), 0 2px 2px rgba(0, 0, 0, .7);
    }
  }
}


@media screen and (max-width: 550px) {
  .loader-wrapper {
    #loader {
      width: 150px;
      height: 90px;
      margin-left: -75px;
      margin-top: -45px;
    }

    .bar:nth-child(1) {
      left: 0;
    }

    .bar:nth-child(2) {
      left: 20px;
    }

    .bar:nth-child(3) {
      left: 40px;
    }

    .bar:nth-child(4) {
      left: 60px;
    }

    .bar:nth-child(5) {
      left: 80px;
    }

    .bar:nth-child(6) {
      left: 100px;
    }

    .bar:nth-child(7) {
      left: 120px;
    }

    .bar:nth-child(8) {
      left: 140px;
    }

    @-webkit-keyframes jump-loader {
      0% {
        width: 10px;
        height: 90px;
      }
      50% {
        width: 13px;
        height: 100px;
        margin-left: -3px;
        margin-top: -10px;
        box-shadow: inset 0 1px 1px rgba(255, 255, 255, .2), 0 20px 25px rgba(0, 0, 0, .7);
      }
      100% {
        width: 10px;
        height: 90px;
        margin-left: 0;
        margin-top: 0;
        box-shadow: inset 0 1px 1px rgba(255, 255, 255, .2), 0 2px 2px rgba(0, 0, 0, .7);
      }
    }
  }
}

</style>
