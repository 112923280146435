import {mapGetters, mapMutations} from "vuex";
import {directive as onClickaway} from 'vue-clickaway';
//sections
export default {
    name: "video-player",
    directives: {
        onClickaway: onClickaway,
    },
    components: {},
    props: {
        item: {
            type: Object,
            default: () => {
            }
        },
        description: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            isPlayVideo: false,
        }
    },
    created() {
    },
    computed: {
        ...mapGetters({})
    },

    methods: {
        ...mapMutations({}),
        playVideo() {
            this.isPlayVideo = !this.isPlayVideo
        },
        away: function () {
            this.playVideo()
        },
    }
}
