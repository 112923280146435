export default {
    name: "main-banner",
    props: {
        item: {}

    },
    data() {
        return {}
    },
    mounted() {
        // this.item = JSON.parse(this.item)
    },
    created() {
    },
    computed: {
        bgImage: function () {
            return this.item.bannerImage || this.item.image
        }
    },
    methods: {}
}
