import Vue from 'vue';
import store from '@/store';
import router from '@/router';
import axios from 'axios';
import VueAxios from 'vue-axios';
import {uuid} from 'vue-uuid';

const camelcaseObjectDeep = require('camelcase-object-deep');

Vue.use(VueAxios, axios);

let promiseCollection = [];

let findPromiseIndex = (url) => {
    return promiseCollection.findIndex(promiseUrl => promiseUrl === url)
}

if (!localStorage.getItem('guid')) {
    localStorage.setItem('guid', uuid.v1())
}


export const $http = axios.create({

    baseURL: process.env.VUE_APP_ROOT_API,
    headers: {
        'Basket-id': `${localStorage.getItem('guid')}`,
        'Authorization': `Bearer ${localStorage.getItem('user_token')}`,
        'Accept': 'application/json',
        'Access-Control-Allow-Origin': '*'
    }
});
$http.interceptors.request.use((config) => {
    config.headers['Authorization'] = `Bearer ${localStorage.getItem('user_token')}`;
    config.headers['X-localization'] = store.getters.currentLanguage.translationKey;
    config.headers['Access-Control-Allow-Origin'] = "X-Requested-With";
    config.headers['Access-Control-Allow-Headers'] = "*";
    config.headers['Access-Control-Allow-Methods'] = "Get, Post, Options";


    let globalRequestsFlag = store.getters['system/globalRequestsFlag'];
    if (globalRequestsFlag) {
        promiseCollection.push(config.url)
    }

    return config;
});
$http.interceptors.response.use((response) => {
        response.data = camelcaseObjectDeep(response.data);

        let index = findPromiseIndex(response.config.url)
        if (index > -1) {
            promiseCollection.splice(index, 1);
        }

        if (!promiseCollection.length) {
            store.commit(`system/SET_REQUESTS_FLAG`, false)
        }

        return response;
    }, (error) => {

        let index = findPromiseIndex(error.response.config.url)
        if (index > -1) {
            promiseCollection.splice(index, 1);
        }
        if (!promiseCollection.length) {
            store.commit(`system/SET_REQUESTS_FLAG`, false)
        }

        switch (error.response.status) {
            case 500: {
                router.push({name: 'server-not-found'}).catch(() => {
                    console.log()
                });
                break;
            }
            case 404: {

                router.push({name: 'page-not-found', replace: true}).catch(() => {
                    console.log()
                });

                break;
            }
            case 401: {
                // router.push({name: 'sing-in'}).catch(() => {console.log()});
                store.commit('auth/RESET_TOKEN');
                store.commit('auth/SHOW_REGISTER_POPUP', true);
                break;
            }
            default: {
                break;
            }
        }
        return Promise.reject(camelcaseObjectDeep(error.response));
    }
);

export default function install(Vue) {
    Object.defineProperty(Vue.prototype, '$http', {
        get() {
            return $http
        }
    })
}

// export function getRedirectUri(uri) {
//   try {
//     return !isUndefined(uri)
//       ? `${$window.location.origin}${uri}`
//       : $window.location.origin;
//   } catch (e) {
//   }
//
//   return uri || null;
// }
