import {mapMutations, mapGetters} from "vuex";
import {Carousel, Slide} from 'vue-carousel';


export default {
    name: "banner",
    components: {
        Carousel,
        Slide,
    },
    props: {
        list: {
            type: Array,
            default: () => []
        },
        homePage: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            currentSlide: 0,
            acc: 0,
            title: '',
            id: '',
            url: '',
            src: '',
            desc: '',
            button: '',
            smallImage: '',
            collection: [],
            showPagination: false
        }
    },
    mounted() {

        this.collection = this.list
        this.collection.forEach((el, idx) => {
            el.id = idx + 1
        })

        this.smallImage = this.collection[0].smallImage
        this.currentSlide = this.collection[0]
        this.title = this.collection.title
        this.id = this.collection.id
        this.desc = this.collection.description
        this.button = this.collection[0].buttonText
        this.getDataFromSlide()

    },

    watch: {
        '$route.params.slug': {
            handler: function () {
                this.collection = []
            },
            deep: true,
            immediate: true
        }
    },
    computed: {
        ...mapGetters({
            pageContent: 'setting/pageContent'
        })
    },
    created() {
        window.innerWidth <= 800 ? this.showPagination = true : this.showPagination = false
    },
    beforeDestroy() {
        this.collection = []
        this.smallImage = ''
        this.currentSlide = ''
        this.title = ''
        this.id = ''
        this.desc = ''
        this.button = ''
    },

    methods: {
        getDataFromSlide() {
            let length = this.collection.length
            if (this.acc !== length - 1) {
                this.acc++
            } else {
                this.acc = 0
            }
            this.title = this.collection[this.acc].title
            this.id = this.collection[this.acc].id
            this.desc = this.collection[this.acc].description
        },
        carouselNav($event, direction) {
            const carousel = this.$refs['carousel'];
            carousel.advancePage(direction);
        },
        ...mapMutations({}),
    }
}
