import {mapMutations, mapActions, mapGetters} from "vuex";
import {email, maxLength, minLength, required} from "vuelidate/lib/validators";

//sections


export default {
    name: "trial-batch",
    components: {},
    props: {
        title: {
            type: String,
            default: ''
        },
        formType: {
            type: String,
            default: 'trial_batch'
        }
    },
    data() {
        return {
            validationErrors: {},
            payload: {
                name: '',
                email: '',
                phone: '',
                message: ''

            }
        }
    },
    validations: {
        payload: {
            name: {
                required,
                minLength: minLength(3),
                maxLength: maxLength(20)
            },
            email: {
                required,
                maxLength: maxLength(60),
                email,
            },
            phone: {
                required,
                minLength: minLength(19),
            },
            message: {
                required,
                minLength: minLength(10),
                maxLength: maxLength(300),
            },
        },
    },
    created() {
    },
    mounted() {

    },
    watch: {},
    computed: {
        ...mapGetters({
            orderTrialBatch: 'landings/orderTrialBatch',
            feedbackLoading: 'setting/feedbackLoading'
        }),
        emailErrors() {
            let error = [];
            if (!this.$v.payload.email.$dirty) {
                return error;
            }
            if (!this.$v.payload.email.maxLength) {
                error.push(this.$t('validationMax') + '50');
            }
            if (!this.$v.payload.email.required) {
                error.push(this.$t('validation.required'))
            }
            if (!this.$v.payload.email.email) {
                error.push(this.$t('validationEmail'))
            }
            if (this.validationErrors.email) {
                this.validationErrors.email.forEach((row) => {
                    error.push(row);
                });
            }
            return error;
        },
        nameErrors() {
            let error = [];
            if (!this.$v.payload.name.$dirty) {
                return error;
            }

            if (!this.$v.payload.name.required) {
                error.push(this.$t('validation.required'))
            }
            if (!this.$v.payload.name.minLength) {
                error.push(this.$t('validation.minLength', {'size': 3}));
            }
            if (!this.$v.payload.name.maxLength) {
                error.push(this.$t('validation.maxLength', {'size': 20}));
            }
            if (this.validationErrors.name) {
                this.validationErrors.name.forEach((row) => {
                    error.push(row);
                })
            }
            return error;
        },
        phoneErrors() {
            let error = [];
            if (!this.$v.payload.phone.$dirty) {
                return error;
            }
            if (!this.$v.payload.phone.required) {
                error.push(this.$t('validation.required'))
            }
            if (!this.$v.payload.phone.minLength) {
                error.push(this.$t('validation.minLength', {'size': 11}));
            }
            // if (!this.$v.payload.phone.maxLength) {
            //   error.push(this.$t('validationMax') + '20');
            // }
            if (this.validationErrors.phone) {
                this.validationErrors.phone.forEach((row) => {
                    error.push(row);
                })
            }
            return error;
        },
        messageErrors() {
            let error = [];
            if (!this.$v.payload.message.$dirty) {
                return error;
            }
            if (!this.$v.payload.message.required) {
                error.push(this.$t('validation.required'))
            }
            if (!this.$v.payload.message.minLength) {
                error.push(this.$t('validation.minLength', {'size': 12}));
            }
            if (!this.$v.payload.message.maxLength) {
                error.push(this.$t('validation.maxLength', {'size': 300}));
            }
            if (this.validationErrors.message) {
                this.validationErrors.message.forEach((row) => {
                    error.push(row);
                })
            }
            return error;
        },
    },
    methods: {
        ...mapMutations({
            isShowTrialBatch: 'landings/ORDER_TRIAL_BATCH',
            isShowConsultationPopup: 'catalog/SHOW_CONSULTATION_POPUP'
        }),
        ...mapActions({
            sendFeedBack: 'setting/SEND_FEEDBACK'
        }),
        sendData() {
            this.$v.$touch();
            if (!this.$v.$invalid) {
                let obj = {};
                obj.name = this.payload.name
                obj.email = this.payload.email;
                obj.message = this.payload.message;
                obj.type = this.formType;
                obj.phone = this.payload.phone.replace(/[^\d]/g, '');
                this.sendFeedBack(obj).then(() => {
                    document.getElementById('html').classList.remove('hide')
                    this.$toasted.success(this.$t('form.send'));
                    this.isShowTrialBatch(false)
                    this.isShowConsultationPopup(false)
                    this.payload.name = null
                    this.payload.email = null
                    this.payload.message = null
                    this.payload.phone = null

                    this.$nextTick(() => {
                        this.$nextTick(() => {
                            this.$v.$reset();
                        });
                    });
                })
                    .catch(() => {
                        this.$toasted.error(this.$t('form.sendError'));
                    })
            }

        }
    }
}
