import {mapMutations, mapGetters} from "vuex";


export default {
    name: "main-slider",
    props: {
        list: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {}
    },
    mounted() {
    },
    watch: {},
    computed: {
        ...mapGetters({
            pageContent: 'setting/pageContent'
        })
    },
    created() {
    },

    methods: {
        ...mapMutations({}),
    }
}
