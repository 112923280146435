import {mapMutations, mapGetters} from "vuex";
import {chunk} from 'lodash'
import {directive as onClickaway} from 'vue-clickaway';
import Vue from "vue";

export default {
    name: "cover",
    directives: {
        onClickaway: onClickaway,
    },
    components: {},
    props: {
        list: {
            type: Array,
            default: () => []
        },
        item: {
            type: Object,
            default: () => {
            }
        }
    },
    data() {
        return {
            groups: [],
            ytVideos: [],
            isPlayVideo: false,
            videoId: '',
            playerVars: {
                autoplay: 1,
                playsinline: 1,
            },
        }
    },
    beforeUpdate() {
        let list = [], size = this.list.length;
        list[size - 1] = 5
        let groupSize = 6;
        this.groups = chunk(this.list, groupSize)
    },
    mounted() {
        let list = [], size = this.list.length;
        list[size - 1] = 5
        let groupSize = 6;
        this.ytVideos = this.list
            .filter((item) => {
                return item.isVideo && item.isYoutube
            }).map((item) => {
                return {
                    item,
                    player: null
                }
            })
        this.groups = chunk(this.list, groupSize)
        window.onYouTubeIframeAPIReady = this.loadPlayer;
        const tag = document.createElement('script');
        tag.src = 'https://www.youtube.com/iframe_api';
        const firstScriptTag = document.getElementsByTagName('script')[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
        setTimeout(() => {
            this.loadPlayer()
        }, 500)
    },
    created() {
        let list = [], size = this.list.length;
        list[size - 1] = 5
        let groupSize = 6;
        this.groups = chunk(this.list, groupSize)
    },
    playVideo() {
        this.isPlayVideo = !this.isPlayVideo
    },
    computed: {
        ...mapGetters({
            pageContent: 'setting/pageContent'
        })
    },
    methods: {
        ...mapMutations({}),
        loadPlayer() {
            this.ytVideos
                .map(({item}) => {
                    const link = item.videoLink;
                    const id = 'video-id-' + item.id

                    const player = new YT.Player(id, {
                        height: '360',
                        width: '600',
                        videoId: `${link}`,
                        playerVars: {
                            autoplay: 1,
                            controls: 0,
                            modestbranding: 1,
                            loop: 1,
                            autohide: 0,
                            mute: 1,
                            playsinline: 1,
                            playlist: `${link}`,
                        },
                        events: {
                            onReady: this.onPlayerReady,
                        },
                    })
                    return {
                        item,
                        player,
                    }
                })
            ;
        },
        onPlayerReady(event) {
            event.target.setVolume(50);
            event.target.playVideo();
        },
        goToProjects() {
            this.$router.push({name: 'projects'}).catch(() => {
                console.log()
            })
        },
        playVideo(item) {
            this.isPlayVideo = !this.isPlayVideo
            this.videoId = item.id
        },
        away: function () {
            this.isPlayVideo = !this.isPlayVideo
            this.videoId = ''
        },
        goTo(item) {
            this.$router.push({name: 'project', params: {slug: item.targetSource.slug}})
        },
    }
}
