import {mapMutations} from "vuex";


export default {
    name: "partner",
    components: {},
    props: {
        item: {
            type: Object,
            default: () => {
            }
        }
    },
    data() {
        return {}
    },
    created() {
    },
    computed: {},
    methods: {
        visibilityChanged(isVisible, entry) {
            let collection = document.querySelectorAll('.animatedPath')
            if (entry.isIntersecting) {
                collection.forEach(el => {
                    el.classList.add('roue-details')
                })

            } else {
                collection.forEach(el => {
                    el.classList.remove('roue-details')
                })
            }

        },
        goToConstructor() {
            this.$router.push({name: 'proektant'})
        },
        ...mapMutations({}),
    }
}
