import {mapActions, mapGetters, mapMutations} from "vuex";
import circleLoader from "@/components/misc/circle-loader.vue";
import uniqBy from 'lodash/uniqBy';

export default {
    name: "option-reliz",
    components: {
        circleLoader
    },
    props: {
        // currentPrice: {
        //     type: Number,
        // },
        specificationMessage: {
            type: Object,
            default: () => {
            }
        },
        slug: {
            type: String,
            default: () => ''
        },
        specificationReceive: {
            type: Boolean,
            default: false
        },
        specification: {
            type: Object,
            default: () => {
            }
        }
    },
    data() {
        return {
            currentPrice: 0,
            selectError: [],
            selectedAttr: [[], [], []],
            currentSize: '',
            currentProduct: '',
            clickedButton: '',
            messages: '',
        }
    },
    mounted() {

    },
    created() {
        this.getCheckedValue()
    },

    computed: {
        ...mapGetters({
            productAttributes: 'constructors/calculatorSpecifications',
            roofSize: 'constructors/roofSize',
            specificationList: 'constructors/specificationList',
            specificationsLoaded: 'constructors/specificationsLoaded',
            currentSpecification: 'constructors/currentSpecification'
        }),
        errorRoofSelect() {
            if (this.messages && this.messages[0].name) {
                return this.messages[0].name + ' : ' + this.messages[0].value
            } else {
                return this.messages[0].value
            }
        },


    },
    watch: {
        specificationList() {
            this.currentProduct = ''
            this.messages = []
            this.errorMessages()
        },
        selectedAttr() {
            this.$refs.selectErrorMobile.innerText = ''
            this.$refs.selectError.innerText = ''
            this.$parent.$refs.errorProduct.innerText = ''
        },
    },
    methods: {
        ...mapMutations({
            setCurrentSpecification: 'constructors/SET_CURRENT_SPECIFICATION',
            resetLoader: "constructors/SPECIFICATIONS_LOADED"
        }),
        ...mapActions({
            getSpecifications: 'constructors/GET_SPECIFICATIONS',
        }),
        getCheckedValue() {
            if (this.specification.attributes.selectedProductAttributes) {
                this.specification.attributes.data.forEach((el, idx) => {
                    for (let key in this.specification.attributes.selectedProductAttributes) {
                        if (parseInt(el.id) === parseInt(key)) {
                            el.attributeValues.data.forEach(elem => {
                                if (parseInt(elem.id) === parseInt(this.specification.attributes.selectedProductAttributes[key].attributeValueId)) {
                                    this.selectedAttr[idx] = elem
                                }
                            })
                        }
                    }
                })

            }

        },
        errorMessages() {
            this.messages = []
            if (this.currentSpecification === this.specification.id) {
                return this.messages = this.specificationList.specification.messages
            }
            if (this.productAttributes) {
                this.productAttributes.calculatorType.specifications.data.forEach(el => {
                    if (el.id === this.specification.id) {
                        this.messages = []
                        return this.messages = el.messages
                    }
                })
            }
        },
        getSpecificationsList() {
            this.clickedButton = this.specification.id
            let obj = {}
            obj.messages = {}
            obj.attributes = []
            obj.squares = []


            if (this.specification.attributes.data.length) {
                let squares = {}
                let squares2 = {}
                this.selectedAttr.forEach((el, idx) => {
                    if (Object.keys(el).length) {

                        let attribute = {}
                        attribute.attribute_id = el.productAttributeId
                        attribute.value_id = el.id


                        if (this.roofSize.length) {
                            squares.length = this.roofSize.length
                            squares.scope = this.roofSize.width
                        }
                        if (this.roofSize.roofLeftLength) {
                            squares.length = this.roofSize.roofLeftLength
                            squares.scope = this.roofSize.roofLeftWidth
                            squares2.length = this.roofSize.roofRightLength
                            squares2.scope = this.roofSize.roofRightWidth
                        }

                        obj.specification_id = this.specification.id
                        obj.calculator_type_slug = this.slug

                        obj.attributes.push(attribute)

                        obj.type = 'products'
                    }
                })
                if (this.roofSize.roofLeftLength) {
                    obj.squares.push(squares2)
                }
                obj.squares.push(squares)
            } else {
                let squares = {}

                squares.length = this.roofSize.length
                squares.scope = this.roofSize.width
                obj.squares.push(squares)
                obj.specification_id = this.specification.id
                obj.calculator_type_slug = this.slug
                obj.type = 'products'
            }


            if (this.roofSize.width < 1000) {
                this.$parent.$refs.errorProductA1.innerText = this.$t('roofingCalculator.validation.min_length', {min: 1000})
            }
            if (this.roofSize.length < 1000) {
                this.$parent.$refs.errorProductD1.innerText = this.$t('roofingCalculator.validation.min_length', {min: 1000})
            }
            if (this.roofSize.roofLeftLength < 1000) {
                this.$parent.$refs.errorProductA1.innerText = this.$t('roofingCalculator.validation.min_length', {min: 1000})
            }
            if (this.roofSize.roofLeftWidth < 1000) {
                this.$parent.$refs.errorProductD1.innerText = this.$t('roofingCalculator.validation.min_length', {min: 1000})
            }
            if (this.roofSize.roofRightLength < 1000) {
                this.$parent.$refs.errorProductA2.innerText = this.$t('roofingCalculator.validation.min_length', {min: 1000})
            }
            if (this.roofSize.roofRightWidth < 1000) {
                this.$parent.$refs.errorProductD2.innerText = this.$t('roofingCalculator.validation.min_length', {min: 1000})
            }
            let hasAtLeastOne = this.selectedAttr.find(attr => attr.id)

            if (
                hasAtLeastOne
                && (
                    (!this.specification.attributes.data.length && this.roofSize.length >= 1000 && this.roofSize.width >= 1000)
                    || (this.roofSize.roofLeftLength >= 1000 && this.roofSize.roofLeftWidth >= 1000 && this.roofSize.roofRightLength >= 1000 && this.roofSize.roofRightWidth >= 1000)
                    || (this.roofSize.length >= 1000 && this.roofSize.width >= 1000)
                )
            ) {
                this.setCurrentSpecification(this.specification.id)


                this.getSpecifications(obj).then(() => {
                    this.currentProduct = this.specification.id
                    this.messages = []
                    this.errorMessages()
                    this.setCurrentSpecification('')
                    this.currentPrice = this.specificationList.specification.totalPrice

                })

            }
            if (this.specification.attributes.data.length && !obj.attributes.length) {
                if (window.innerWidth <= 1180) {
                    return this.$refs.selectErrorMobile.textContent = this.$t('validation.attribute')
                } else {
                    return this.$refs.selectError.textContent = this.$t('validation.attribute')
                }
            }

        }
    }
}
