import {$http} from '@/utils/https'
import {translation} from '@/helpers/translation';
import {globalLoader} from '@/helpers/variables/index';

import {
    CHANGE_PAGE_LOADING, SEND_FEEDBACK_LOADING,
    SET_CONTACTS_FOOTER,
    SET_CONTACTS_HEADER,
    SET_PAGE_CONTENT,
    SET_SEND_FEEDBACK,
    SET_TRANSLATIONS,
    SET_VIDEOS_PAGE,
    SHOW_THANKS_POPUP,
    SET_PRIVACY_POLICY, SET_SITEMAP

} from '../mutation-types';

import {
    GET_MAIN_CONTACTS,
    GET_PAGE_CONTENT, GET_PRIVACY_POLICY, GET_SITEMAP,
    GET_TRANSLATIONS,
    GET_VIDEO_GALLERY,
    SEND_FEEDBACK,
    SET_SUBSCRIPTION,

} from '../action-types';
import axios from "axios";

const state = {
    translations: null,
    pageContent: '',
    pageLoading: false,
    feedback: '',
    showThanksPopup: false,
    videoGallery: '',
    limit: 9,
    headerDates: "",
    footerDates: "",
    feedbackLoading: false,
    privacyPolicy: '',
    siteMap: ''
};

const getters = {
    siteMap: state => state.siteMap,
    privacyPolicy: state => state.privacyPolicy,
    feedbackLoading: state => state.feedbackLoading,
    videoGallery: state => state.videoGallery,
    translations: state => state.translations,
    pageContent: state => state.pageContent,
    pageLoading: state => state.pageLoading,
    feedback: state => state.feedback,
    showThanksPopup: state => state.showThanksPopup,
    headerDates: state => state.headerDates,
    footerDates: state => state.footerDates
};

const actions = {
    [GET_SITEMAP]: async ({commit}) => {
        globalLoader(true);
        try {
            const response = await $http.get('v1/sitemap');
            commit(SET_SITEMAP, response.data);

        } catch (e) {
            throw e;
        } finally {
            globalLoader(false);
        }
    },
    [GET_PRIVACY_POLICY]: async ({commit}) => {
        globalLoader(true);
        try {
            const response = await $http.get('v1/terms');
            commit(SET_PRIVACY_POLICY, response.data);

        } catch (e) {
            throw e;
        } finally {
            globalLoader(false);
        }
    },
    [GET_TRANSLATIONS]: async ({commit}) => {
        globalLoader(true);
        try {
            const response = await $http.get('v1/translations');
            let result = Object.assign(response.data, translation);
            commit(SET_TRANSLATIONS, result);
            return result;
        } catch (e) {
            throw e;
        } finally {
            globalLoader(false);
        }
    },
    [GET_MAIN_CONTACTS]: async ({commit}, payload) => {
        commit(CHANGE_PAGE_LOADING, true);
        try {
            const response = await $http.get(`v1/contacts/${payload.type}`);
            if (payload.type === 'header') {
                commit(SET_CONTACTS_HEADER, response.data.data)
            } else {
                commit(SET_CONTACTS_FOOTER, response.data.data)
            }
            // commit(SET_PAGE_CONTENT, response.data.data);
        } catch (e) {
            throw e;
        } finally {
            commit(CHANGE_PAGE_LOADING, false);
        }
    },
    [GET_PAGE_CONTENT]: async ({commit}, slug) => {
        commit(CHANGE_PAGE_LOADING, true);
        try {
            const response = await $http.get(`v1/page/${slug}`);
            commit(SET_PAGE_CONTENT, response.data.data);
        } catch (e) {
            throw e;
        } finally {
            commit(CHANGE_PAGE_LOADING, false);
        }
    },
    [GET_VIDEO_GALLERY]: async ({commit}) => {
        commit(CHANGE_PAGE_LOADING, true);
        try {
            const response = await $http.get(`v1/galleries?page=1&per_page=${state.limit}`);
            commit(GET_VIDEO_GALLERY, response.data.data);
        } catch (e) {
            throw e;
        } finally {
            commit(CHANGE_PAGE_LOADING, false);
        }
    },
    [SET_SUBSCRIPTION]: async ({commit}, payload) => {

        try {
            const result = await $http.post('v1/subscriptions/create', payload);
            // commit(SET_SEND_FEEDBACK, result.data.data)
        } catch (e) {
            throw e;
        } finally {

        }
    },
    [SEND_FEEDBACK]: async ({commit}, payload) => {
        commit(SEND_FEEDBACK_LOADING, true)
        try {
            const result = await $http.post('v1/feedback', payload);
            commit(SET_SEND_FEEDBACK, result.data.data)
        } catch (e) {
            throw e;
        } finally {
            commit(SEND_FEEDBACK_LOADING, false)
        }
    },
};

const mutations = {
    [SEND_FEEDBACK_LOADING](state, status) {
        state.feedbackLoading = status
    },
    [SET_TRANSLATIONS](state, status) {
        state.translations = status;
    },
    [CHANGE_PAGE_LOADING](state, status) {
        state.pageLoading = status
    },
    [SET_PAGE_CONTENT](state, data) {
        state.pageContent = data
    },
    [SET_SEND_FEEDBACK](state, data) {
        state.feedback = data
    },
    [SHOW_THANKS_POPUP](state, status) {
        state.showThanksPopup = status
    },
    [GET_VIDEO_GALLERY](state, data) {
        state.videoGallery = data
    },
    [SET_VIDEOS_PAGE](state) {
        state.limit += 9
    },
    [SET_CONTACTS_HEADER](state, data) {
        state.headerDates = data
    },
    [SET_CONTACTS_FOOTER](state, data) {
        state.footerDates = data
    },
    [SET_PRIVACY_POLICY](state, data) {
        state.privacyPolicy = data
    },
    [SET_SITEMAP](state, data) {
        state.siteMap = data
    }

};

export default {
    state,
    getters,
    actions,
    mutations,
};
