export default {
    name: "product-card-loading",
    props: {
        item: {
            type: Object,
            default: () => {
            }
        },
        isLoad: false
    },
    data() {
        return {}
    },
    created() {


    },
    methods: {},
}
