import {$http} from '@/utils/https'
import axios from 'axios'

import {
    CHANGE_CATEGORY_LOADING,
    CHANGE_ONE_CATEGORY_LOADING,
    CHANGE_PRODUCT_LOADING,
    FILTERED_PRODUCTS_LOADING,
    PRODUCT_FILTERS_LOADING,
    SET_CATEGORY,
    SET_CURRENT_CATEGORY,
    SET_FILTERED_PRODUCTS,
    SET_ONE_CATEGORY,
    SET_PRODUCT,
    SET_PRODUCT_FILTERS,
    SAVE_ATTRIBUTES_VALUE,
    SKIP_PAGINATION,
    SET_SEARCH_LIST,
    SEARCH_LIST_LOADING,
    CLEAR_SEARCH_LIST,
    SHOW_MOBILE_FILTERS,
    SHOW_CONSULTATION_POPUP,
} from '../mutation-types';

import {
    CATALOG_SEARCH,
    GET_CATEGORY,
    GET_CURRENT_CATEGORY,
    GET_FILTERED_PRODUCTS,
    GET_ONE_CATEGORY,
    GET_PRODUCT,
    GET_PRODUCT_FILTERS, PRODUCT_SEARCH
} from '../action-types';

const state = {
    searchList: [],
    categoryList: [],
    oneCategoryList: [],
    categoriesIsLoading: false,
    oneCategoriesIsLoading: false,
    currentProduct: '',
    currentProductLoading: false,
    currentCategory: '',
    productFilters: '',
    productFiltersLoading: false,
    filteredProducts: '',
    filteredProductsLoading: false,
    attributesValue: '',
    pagination: {
        per_page: 12,
        page: 1
    },
    searchListLoading: '',
    showMobileFilters: false,
    showConsultationPopup: false
};

const getters = {
    showConsultationPopup: state => state.showConsultationPopup,
    showMobileFilters: state => state.showMobileFilters,
    searchListLoading: state => state.searchListLoading,
    searchList: state => state.searchList,
    pagination: state => state.pagination,
    attributesValue: state => state.attributesValue,
    filteredProductsLoading: state => state.filteredProductsLoading,
    filteredProducts: state => state.filteredProducts,
    productFiltersLoading: state => state.productFiltersLoading,
    productFilters: state => state.productFilters,
    categoryList: state => state.categoryList,
    categoriesIsLoading: state => state.categoriesIsLoading,
    oneCategoryList: state => state.oneCategoryList,
    oneCategoriesIsLoading: state => state.oneCategoriesIsLoading,
    currentProduct: state => state.currentProduct,
    currentProductLoading: state => state.currentProductLoading,
    currentCategory: state => state.currentCategory
};

const actions = {
    [GET_CURRENT_CATEGORY]: async ({commit}, slug) => {
        // commit(CHANGE_CATEGORY_LOADING, true);
        try {
            const response = await $http.get(`v1/categories/${slug}`);
            commit(SET_CURRENT_CATEGORY, response.data.data);
        } catch (e) {
            throw e;
        } finally {
            // commit(CHANGE_CATEGORY_LOADING, false);
        }
    },
    [GET_CATEGORY]: async ({commit}) => {
        commit(CHANGE_CATEGORY_LOADING, true);
        try {
            const response = await $http.get(`v1/categories`);
            commit(SET_CATEGORY, response.data.data);
        } catch (e) {
            throw e;
        } finally {
            commit(CHANGE_CATEGORY_LOADING, false);
        }
    },
    [GET_ONE_CATEGORY]: async ({commit}, slug) => {
        commit(CHANGE_ONE_CATEGORY_LOADING, true);

        try {
            const response = await $http.get(`v1/catalog/${slug}/products`);
            commit(SET_ONE_CATEGORY, response.data);
        } catch (e) {
            throw e;
        } finally {
            commit(CHANGE_ONE_CATEGORY_LOADING, false);
        }
    },
    [GET_PRODUCT]: async ({commit}, slug) => {
        commit(CHANGE_PRODUCT_LOADING, true);

        try {
            const response = await $http.get(`v1/catalog/product/${slug}`);
            commit(SET_PRODUCT, response.data.data);
        } catch (e) {
            throw e;
        } finally {
            commit(CHANGE_PRODUCT_LOADING, false);
        }
    },
    [GET_PRODUCT_FILTERS]: async ({commit}, slug) => {
        commit(PRODUCT_FILTERS_LOADING, true);
        try {
            const response = await $http.get(`v1/catalog/${slug}/filters`);
            commit(SET_PRODUCT_FILTERS, response.data.data);
        } catch (e) {
            throw e;
        } finally {
            commit(PRODUCT_FILTERS_LOADING, false);
        }
    },
    [GET_FILTERED_PRODUCTS]: async ({commit}, payload) => {
        commit(CHANGE_ONE_CATEGORY_LOADING, true);
        try {

            const response = await $http.get(`v1/catalog/${payload.slug}/products?price_min=${payload.price_min}&price_max=${payload.price_max}&per_page=${payload.limit}&page=${payload.page}${payload.data}`);
            commit(SET_FILTERED_PRODUCTS, response.data);
            commit(SAVE_ATTRIBUTES_VALUE, payload)
        } catch (e) {
            throw e;
        } finally {
            commit(CHANGE_ONE_CATEGORY_LOADING, false);
        }
    },
    [CATALOG_SEARCH]: async ({commit}, payload) => {
        commit(SEARCH_LIST_LOADING, true);
        try {
            const response = await $http.get(`v1/search?text=${payload.text}`);
            commit(SET_SEARCH_LIST, response.data.data);
        } catch (e) {
            throw e;
        } finally {
            commit(SEARCH_LIST_LOADING, false);
        }
    },
    [PRODUCT_SEARCH]: async ({commit}, payload) => {
        commit(CHANGE_ONE_CATEGORY_LOADING, true);
        try {
            const response = await $http.get(`v1/catalog/${payload.slug}/products?search=${payload.search}`);
            commit(SET_FILTERED_PRODUCTS, response.data);
        } catch (e) {
            throw e;
        } finally {
            commit(CHANGE_ONE_CATEGORY_LOADING, false);
        }
    },
};

const mutations = {
    [SEARCH_LIST_LOADING](state, status) {
        state.searchListLoading = status
    },
    [SET_SEARCH_LIST](state, data) {
        state.searchList = data
    },
    [FILTERED_PRODUCTS_LOADING](state, status) {
        state.filteredProductsLoading = status
    },
    [SET_FILTERED_PRODUCTS](state, data) {
        state.oneCategoryList = data
    },
    [PRODUCT_FILTERS_LOADING](state, status) {
        state.productFiltersLoading = status
    },
    [SET_PRODUCT_FILTERS](state, data) {
        state.productFilters = data
    },
    [SET_CATEGORY](state, data) {
        state.categoryList = data;
    },
    [SET_ONE_CATEGORY](state, data) {
        state.oneCategoryList = data;
    },
    [CHANGE_CATEGORY_LOADING](state, status) {
        state.categoriesIsLoading = status;
    },
    [CHANGE_ONE_CATEGORY_LOADING](state, status) {
        state.oneCategoriesIsLoading = status;
    },
    [SAVE_ATTRIBUTES_VALUE](state, data) {
        state.attributesValue = data;
    },
    [SET_PRODUCT](state, data) {
        state.currentProduct = data
        let arr = []
    },
    [CHANGE_PRODUCT_LOADING](state, status) {
        state.currentProductLoading = status;
    },
    [SET_CURRENT_CATEGORY](state, data) {
        state.currentCategory = data
    },
    [SKIP_PAGINATION](state, value) {
        state.pagination.page = value
    },
    [CLEAR_SEARCH_LIST](state) {
        state.searchList = []
    },
    [SHOW_MOBILE_FILTERS](state, status) {
        state.showMobileFilters = status
    },
    [SHOW_CONSULTATION_POPUP](state, status) {
        state.showConsultationPopup = status
    }
};

export default {
    state,
    getters,
    actions,
    mutations,
};
