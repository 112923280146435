import {mapMutations, mapActions, mapGetters} from "vuex";

//sections
import SmallLoader from "@/components/small-loader/index.vue";

export const loginByPhone = {
    name: "registration",
    components: {
        SmallLoader,
    },
    props: {
        showPopups: {
            type: Boolean,
            default: true
        },
    },
    data() {
        return {
            selectedService: [],
            disabledCode: false,
            disabledPhone: false,
            codeLoader: false,
            phone: '',
            code: '',
        }
    },
    created() {
    },
    mounted() {

    },
    watch: {
        phone() {
            this.smsLogin({
                phone: this.phone.replace(/[^\d]/g, ''),
                code: this.code
            })
            if (this.phone.length === 19) {
                this.sendPhone({phone: this.phone.replace(/[^\d]/g, '')}).then(() => {
                    this.$toasted.success(this.responseCode.message);
                    setTimeout(() => {
                        /*todo code*/
                        this.$toasted.success(this.responseCode.code);
                    }, 1500)
                    this.disabledCode = false
                    setTimeout(() => {
                        this.$refs.enterCode.focus()
                    }, 50)
                    // this.getCodeFromSms()
                }).catch((error) => {
                    console.log()
                    this.$toasted.error(error.message);
                })
            } else {
                this.disabledCode = false
            }

        },
        'code': function () {
            this.smsLogin({
                phone: this.phone,
                code: this.code
            })

            if (this.code.length === 6) {
                this.disabledCode = false
                this.disabledPhone = true
                this.sendConfirmCode()
            }
        }
    },
    computed: {
        ...mapGetters({
            sendingCode: 'auth/setRegisterCodeLoading',
            responseCode: 'auth/setRegisterCode',
            sendingCheckCode: 'auth/setRegisterCheckCodeLoading',
            setRegisterCheckCode: 'auth/setRegisterCheckCode'
        })
    },
    methods: {
        ...mapMutations({
            smsLogin: 'order/SET_LOGIN_SMS_CODE',
            closeThanksPopup: 'setting/SHOW_THANKS_POPUP',
            closeRegisterPopup: 'auth/SHOW_REGISTER_POPUP',
            setToken: 'auth/SET_TOKEN',
            isShowAuthPopup: 'auth/SHOW_AUTH_POPUP',
            isShowAuthThanksPopup: 'auth/SHOW_AUTH_POPUP_THANKS'
        }),
        ...mapActions({
            sendPhone: 'auth/REGISTER_SEND_CODE',
            checkCode: 'auth/REGISTER_CHECK_CODE'
        }),
        openContactSelect() {
            this.openContacts = !this.openContacts
        },
        sendConfirmCode() {
            this.checkCode({
                phone: this.phone.replace(/[^\d]/g, ''),
                code: this.code
            }).then(() => {
                if (this.setRegisterCheckCode.userWasCreated) {
                    this.closeRegisterPopup(false)
                    this.closeThanksPopup(true)
                    this.$toasted.success(this.$t('auth.success.register'));
                } else {
                    this.closeRegisterPopup(false)
                    this.isShowAuthPopup(false)
                    this.isShowAuthThanksPopup(true)
                    this.$toasted.success(this.$t('auth.success.login'));
                }


                this.setToken(this.setRegisterCheckCode)


            }).catch(() => {
                this.$toasted.error(this.$t('form.sendError'));
            })
        },
        confirmOrder() {
        },
        // getCodeFromSms() {
        //   if ('OTPCredential' in window) {
        //     window.addEventListener('DOMContentLoaded', e => {
        //       const ac = new AbortController();
        //       navigator.credentials.get({
        //         otp: {transport: ['sms']},
        //         signal: ac.signal
        //       }).then(otp => {
        //         this.$refs.enterCode.append(otp.code)
        //         this.code = otp.code
        //       }).catch(err => {
        //
        //       });
        //     })
        //     if (!window.DOMContentLoaded) {
        //       this.code = this.responseCode.debug.code
        //       this.sendConfirmCode()
        //     }
        //   } else {
        //     this.code = this.responseCode.debug.code
        //   }
        // }
    }
}
